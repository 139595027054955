import React, {useRef} from 'react'
import Link from 'next/link'
import {useTranslation} from 'next-i18next'

import useTokenLandingStore, {TokenLandingTypes} from '@feature/landing/pages/token/hooks/useTokenLandingStore'
import {useIsVisible} from '@hook/useIsVisible'
import useWindowSize from '@hook/useWindowSize'

import FadeInView from '@component/animation/fadeIn/FadeInView'
import Text from '@component/Text/Text'

import img_cadenza from '@image/token/img_cadenza.webp'
import img_bitmex from '@image/token/img_bitmex.webp'
import img_mirana from '@image/token/img_mirana.webp'
import img_c3 from '@image/token/img_c3.webp'
import img_neoply from '@image/token/img_neoply.webp'
import img_abc from '@image/token/img_abc.webp'
import {createLog} from '@util/logs'
import WizImage from '@component/images/WizImage'

type TBacker = {
    id: string
    logo: React.ReactNode
    url: string
}

interface IProps {
    className?: string
}

const WEB_LOGO_WIDTH = 190
const WEB_LOGO_HEIGHT = 60
const MOBILE_LOGO_WIDTH = 79
const MOBILE_LOGO_HEIGHT = 25
const TokenBackersContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const {isMd} = useWindowSize()

    const {pageType} = useTokenLandingStore()
    const fadeAnimationRef = useRef(null)
    const isVisible = useIsVisible(fadeAnimationRef)

    const backers: TBacker[] = [
        {
            id: 'cadenza',
            logo: <WizImage src={img_cadenza} width={isMd ? MOBILE_LOGO_WIDTH : WEB_LOGO_WIDTH} />,
            url: 'https://cadenza.vc/',
        },
        {
            id: 'bitmex',
            logo: <WizImage src={img_bitmex} width={isMd ? MOBILE_LOGO_WIDTH : WEB_LOGO_WIDTH} />,
            url: 'https://www.bitmex.com/',
        },
        {
            id: 'mirana',
            logo: <WizImage src={img_mirana} width={isMd ? MOBILE_LOGO_WIDTH : WEB_LOGO_WIDTH} />,
            url: 'https://www.mirana.xyz/team',
        },
        {
            id: 'c3',
            logo: <WizImage src={img_c3} width={isMd ? MOBILE_LOGO_WIDTH : WEB_LOGO_WIDTH} />,
            url: 'https://www.c3venturecapital.com/',
        },
        {
            id: 'neowiz',
            logo: <WizImage src={img_neoply} width={isMd ? MOBILE_LOGO_WIDTH : WEB_LOGO_WIDTH} />,
            url: 'https://www.neowiz.com/',
        },
        {
            id: 'abc_partners',
            logo: <WizImage src={img_abc} width={isMd ? MOBILE_LOGO_WIDTH : WEB_LOGO_WIDTH} />,
            url: 'http://www.abc-partners.com/',
        },
    ]
    return (
        <section
            ref={fadeAnimationRef}
            className={`layout-full_desktop max-w-[1280px] py-[200px] lg2:py-[150px] md:py-[100px] sm:py-[70px] lg2:px-[40px] md:px-[20px] space-y-[150px] lg2:space-y-[100px] md:space-y-[50px]`}>
            <div className={'space-y-[40px] md:space-y-[20px]'}>
                <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.TOKEN}>
                    <Text
                        className={
                            'text-white text-center text-landing_heading2 md:text-landing_body_large md:font-semibold !font-landing'
                        }>
                        {t('landingTokenSale.TokenInfo.backers.title')}
                    </Text>
                </FadeInView>
                <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.TOKEN} delay={'delay-500'}>
                    <Text className={'text-white text-center typo-landing_body_medium md:typo-landing_body_caption'}>
                        {t('landingTokenSale.TokenInfo.backers.desc')}
                    </Text>
                </FadeInView>
            </div>
            <div className={'grid grid-cols-3 gap-[32px] md:gap-[10px]'}>
                {backers?.map(backer => (
                    <Link
                        onClick={() => {
                            createLog('event', 'token_landing_backers', {backer: backer?.id})
                        }}
                        href={backer?.url}
                        key={backer?.id}
                        className={
                            ' flex justify-center items-center bg-[#1F1F29] rounded-[10px] py-[70px] lg2:py-[40px] md:py-[20px]'
                        }
                        target={'_blank'}>
                        {backer?.logo}
                    </Link>
                ))}
            </div>
        </section>
    )
}
export default TokenBackersContainer
