import React, {CSSProperties} from 'react'
import classNames from 'classnames'

interface IProps {
    className?: string
}
const GradientStyle: CSSProperties = {
    WebkitMask: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'destination-out',
    maskComposite: 'exclude',
}

const BorderGradientView: React.FC<IProps> = ({className}) => {
    return (
        <div
            className={classNames(
                'absolute inset-0 border border-solid border-transparent bg-origin-border',
                className,
            )}
            style={GradientStyle}
        />
    )
}
export default BorderGradientView
