import React from 'react'
import useWindowSize from '@hook/useWindowSize'

import WebBgSource from '@image/token/img_token_league_feature_web_bg.png'
import MobileBgSource from '@image/token/img_token_league_feature_mobile_bg.png'
import TokenServiceInfoContainer from '@feature/landing/pages/token/containers/service/containers/TokenServiceInfoContainer'
import TokenServiceLeagueFeatureContainer from '@feature/landing/pages/token/containers/service/containers/TokenServiceLeagueFeatureContainer'

interface IProps {
    className?: string
}

const TokenServiceFeaturesContainer: React.FC<IProps> = ({className}) => {
    const {isMd} = useWindowSize()
    return (
        <section className={'relative'}>
            <div
                className={`absolute inset-0 bg-contain bg-no-repeat bg-[center_top_45%] lg2:bg-[center_top_55%] md:bg-[center_top_65%] sm:bg-[center_top_55%] max-w-[1920px] mx-auto -z-10`}
                style={{
                    backgroundImage: `url(${isMd ? MobileBgSource.src : WebBgSource.src})`,
                }}
            />
            <TokenServiceLeagueFeatureContainer className={className} />
            <TokenServiceInfoContainer className={className} />
        </section>
    )
}
export default TokenServiceFeaturesContainer
