import React from 'react'
import classNames from 'classnames'
import TokenInfoStakingContainer from '@feature/landing/pages/token/containers/token/TokenInfoStakingContainer'
import TokenInfoUtilitiesContainer from '@feature/landing/pages/token/containers/token/TokenInfoUtilitiesContainer'
import TokenSaleDistributionContainer from '@feature/landing/pages/token/containers/token/TokenInfoDistributionContainer'
import TokenInfoRoadmapContainer from '@feature/landing/pages/token/containers/token/TokenInfoRoadmapContainer'
import TokenBackersContainer from '@feature/landing/pages/token/containers/token/TokenBackersContainer'

interface IProps {
    className?: string
}

const TokenInfoContainer: React.FC<IProps> = ({className}) => {
    return (
        <div
            data-cy={'tokenLanding_screen_token'}
            className={classNames('transition-opacity duration-500 ease-in-out', className)}>
            <TokenInfoStakingContainer />
            <TokenInfoUtilitiesContainer />
            <TokenSaleDistributionContainer />
            <TokenInfoRoadmapContainer />
            <TokenBackersContainer />
        </div>
    )
}
export default TokenInfoContainer
