import React, {useCallback, useMemo, useRef, useState} from 'react'
import useWindowSize from '@hook/useWindowSize'
import {isNotEmptyArray} from '@util/array'
import WizDotIndicator from '@component/indicator/WizDotIndicator'
import {IAnnouncement} from '@api/announce/getAnnouncements'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Swiper as SwiperCore} from 'swiper/types'
import 'swiper/css'

import IconNext from '@svg/common/ic_btn_next.svg'
import IconBack from '@svg/common/ic_btn_back.svg'
import TokenLandingAnnounceBannerCard from '@feature/landing/pages/token/containers/TokenLandingAnnounceBannerCard'

interface IProps {
    className?: string
    data: IAnnouncement[]
}

const TokenLandingAnnounceBannerContainer: React.FC<IProps> = ({className, data}) => {
    const {isMd, isCustom} = useWindowSize(1325)
    const swiperRef = useRef<SwiperCore>()
    const [swiperCurrentIdx, setSwiperCurrentIdx] = useState(0)
    const swiperStyle = {width: 276, height: 270}
    const swiperLgStyle = {width: 193.5, height: 270}
    const swiperMdStyle = {width: 260, height: 234.5}
    const onClickNext = useCallback(() => {
        swiperRef.current?.slideNext()
    }, [swiperRef])
    const onClickPrev = useCallback(() => {
        swiperRef.current?.slidePrev()
    }, [swiperRef])

    const getSwiperCurrentIndex = (swiper: SwiperCore) => {
        if (isNaN(swiper.realIndex)) return
        setSwiperCurrentIdx(swiper.realIndex)
    }

    return (
        <>
            <div className={`flex items-center z-0 ${className}`}>
                <div className={'mr-[10px] cursor-pointer sm:hidden'} onClick={onClickPrev}>
                    <IconBack width={30} height={30} className={'fill-gray02 dark:fill-dark_gray02'} />
                </div>
                {data && (
                    <Swiper
                        loop
                        style={{zIndex: 0}}
                        spaceBetween={isMd ? 20 : 30}
                        slidesPerView={'auto'}
                        onInit={swiper => (swiperRef.current = swiper)}
                        onBeforeInit={swiper => (swiperRef.current = swiper)}
                        onActiveIndexChange={getSwiperCurrentIndex}>
                        {isNotEmptyArray(data) &&
                            data?.map(announce => {
                                return (
                                    <SwiperSlide
                                        key={announce?.id}
                                        style={isMd ? swiperMdStyle : isCustom ? swiperLgStyle : swiperStyle}>
                                        <TokenLandingAnnounceBannerCard key={announce?.id} {...announce} />
                                    </SwiperSlide>
                                )
                            })}
                    </Swiper>
                )}

                <div className={'ml-[10px] cursor-pointer sm:hidden'} onClick={onClickNext}>
                    <IconNext width={30} height={30} className={'fill-gray02 dark:fill-dark_gray02'} />
                </div>
            </div>
            <WizDotIndicator
                className={'flex items-center justify-center space-x-[6px] mt-[20px] py-[20px] md:mt-[10px]'}
                selectedDotClassName={'bg-dark_gray02'}
                unSelectedDotClassName={'bg-dark_gray06'}
                totalSize={data?.length}
                selectedIndex={swiperCurrentIdx}
            />
        </>
    )
}
export default TokenLandingAnnounceBannerContainer
