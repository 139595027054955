import React from 'react'
import Text from '@component/Text/Text'
import useTokenLandingStore, {
    TokenLandingType,
    TokenLandingTypes,
} from '@feature/landing/pages/token/hooks/useTokenLandingStore'
import classNames from 'classnames'
import {useTranslation} from 'next-i18next'
import {createLog} from '@util/logs'

interface IProps {
    className?: string
    tabRef?: React.MutableRefObject<HTMLDivElement>
}

const TokenLandingTabs: React.FC<IProps> = ({className, tabRef}) => {
    const {t} = useTranslation()
    const {pageType, setPageType} = useTokenLandingStore()

    const getTextStyle = (type: TokenLandingType) =>
        pageType === type && 'bg-gradient-token inline-block text-transparent bg-clip-text'

    const onClickTab = (type: TokenLandingType) => {
        setPageType(type)
        tabRef?.current?.scrollIntoView({behavior: 'smooth', block: 'start'})
        createLog('event', 'token_landing_header_click_tab', {type})
    }
    return (
        <div
            className={classNames(
                'bg-[#1F1F26]/50 h-[105px] md:h-[90px] flex items-center transition-all duration-200 ease-in-out',
                className,
            )}>
            <span
                data-cy={'tokenLanding_tab_about'}
                className={
                    'w-full cursor-pointer pr-[100px] border-r border-white/20 text-right md:text-center md:pr-0'
                }
                onClick={() => onClickTab(TokenLandingTypes.SERVICE)}>
                <Text
                    className={classNames(
                        'text-landing_display1 md:text-landing_body_medium md:font-semibold text-[#C9CAC3] !font-landing',
                        getTextStyle(TokenLandingTypes.SERVICE),
                    )}>
                    {t('landingTokenSale.tab1.title')}
                </Text>
            </span>
            <span
                data-cy={'tokenLanding_tab_token'}
                className={'w-full cursor-pointer pl-[100px] md:text-center md:pl-0'}
                onClick={() => onClickTab(TokenLandingTypes.TOKEN)}>
                <Text
                    className={classNames(
                        'text-landing_display1 md:text-landing_body_medium md:font-semibold text-[#C9CAC3] !font-landing',
                        getTextStyle(TokenLandingTypes.TOKEN),
                    )}>
                    {t('landingTokenSale.tab2.title')}
                </Text>
            </span>
        </div>
    )
}
export default TokenLandingTabs
