import React, {useRef} from 'react'
import Text from '@component/Text/Text'
import TokenDistributionImageEn from '@image/token/img_token_distribution_en.png'
import TokenDistributionImageKo from '@image/token/img_token_distribution_ko.png'
import TokenDistributionMobileImageEn from '@image/token/img_token_distribution_mobile_en.png'
import TokenDistributionMobileImageKo from '@image/token/img_token_distribution_mobile_ko.png'
import FadeInView from '@component/animation/fadeIn/FadeInView'
import {useIsVisible} from '@hook/useIsVisible'
import useTokenLandingStore, {TokenLandingTypes} from '@feature/landing/pages/token/hooks/useTokenLandingStore'
import {useTranslation} from 'next-i18next'
import {useRouter} from 'next/router'
import WizImage from '@component/images/WizImage'

interface IProps {
    className?: string
}

const TokenSaleDistributionContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const language = useRouter()?.locale
    const {pageType} = useTokenLandingStore()
    const fadeAnimationRef = useRef(null)
    const isVisible = useIsVisible(fadeAnimationRef)
    return (
        <section className={className}>
            <div className={`py-[200px] lg2:py-[150px] md:py-[100px] sm:py-[70px] lg2:px-[40px] md:px-[20px]`}>
                <div
                    ref={fadeAnimationRef}
                    className={
                        'layout-full_desktop max-w-[1280px] space-y-[150px] lg2:space-y-[100px] md:space-y-[70px]'
                    }>
                    <div>
                        <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.TOKEN}>
                            <Text
                                className={
                                    'text-white text-landing_heading2 md:text-landing_body_large text-center md:font-semibold !font-landing'
                                }>
                                {t('landingTokenSale.TokenInfo.token.distribution.title')}
                            </Text>
                        </FadeInView>
                    </div>

                    <div className={'max-w-[1280px] mx-auto'}>
                        <WizImage
                            src={language === 'ko' ? TokenDistributionImageKo : TokenDistributionImageEn}
                            containerClassName={'sm:hidden'}
                        />
                        <WizImage
                            src={language === 'ko' ? TokenDistributionMobileImageKo : TokenDistributionMobileImageEn}
                            containerClassName={'hidden sm:flex'}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}
export default TokenSaleDistributionContainer
