import React, {useMemo} from 'react'
import Text from '@component/Text/Text'
import UserThumbnail from '@component/images/UserThumbnail'
import WizImage from '@component/images/WizImage'
import useFormatNumber from '@hook/useFormatNumber'
import useQueryGetExchanges from '@hook/query/core/exchange/useQueryGetExchanges'
import ExchangeTag from '@component/tags/ExchangeTag'
import useWindowSize from '@hook/useWindowSize'

export interface IWelcomeUserRankItemProps {
    rank: number
    thumbnail: any
    name: string
    flag: any
    exchanges?: string[]
    profitRate: number
    chartImage: any
    isSelected?: boolean
}

const TokenServiceUserRankItem: React.FC<IWelcomeUserRankItemProps> = ({
    rank,
    thumbnail,
    name,
    flag,
    exchanges,
    profitRate,
    chartImage,
    isSelected = false,
}) => {
    const {getExchange} = useQueryGetExchanges()
    const {displayPercent} = useFormatNumber()
    const {isSm, isMd} = useWindowSize()

    const filteredExchanges = useMemo(() => {
        if (isMd) {
            return exchanges?.slice(0, 1) ?? []
        } else if (isSm) {
            return exchanges?.slice(0, 1) ?? []
        } else return exchanges ?? []
    }, [isSm, isMd, exchanges])

    return (
        <div
            className={`grid grid-cols-[30px_190px_auto_74px_102px] md:grid-cols-[110px_auto_74px] sm:grid-cols-3 gap-x-[20px] sm:gap-x-[9px] px-[30px] md:px-[15px] py-[15px] border border-[#FFFFFF30] rounded-[10px] shadow-sm min-h-[68px] items-center bg-[#FAFFC210] md:last:hidden`}>
            <div className={'md:hidden'}>
                <Text className={'text-white text-body1 text-center'}>{rank}</Text>
            </div>
            <div className={'overflow-hidden text-body1 flex items-center'}>
                <UserThumbnail src={thumbnail.src} size={38} />
                <Text className={'ml-[10px] md:ml-[8px] text-white text-body3 font-bold md:font-bold'}>{name}</Text>
                <WizImage
                    src={flag}
                    containerClassName={'ml-[5px] md:hidden'}
                    imageClassName={'rounded-full'}
                    width={20}
                    height={20}
                />
            </div>
            <div className={'overflow-hidden text-body1 flex sm:items-center sm:justify-center gap-x-[30px]'}>
                {filteredExchanges?.map(item => (
                    <ExchangeTag
                        key={item}
                        exchange={item}
                        iconSize={20}
                        textClassName={'text-body2 !text-white sm:text-[13px]'}
                    />
                ))}
            </div>
            <div>
                <Text className={'text-right text-body1 text-[#67CCAE]'}>{displayPercent(profitRate)}</Text>
            </div>
            <div className={'text-body1 md:hidden'}>
                <WizImage src={chartImage} width={102} height={34} />
            </div>
        </div>
    )
}

export default TokenServiceUserRankItem
