import React from 'react'
import classNames from 'classnames'
import TokenServiceFeaturesContainer from '@feature/landing/pages/token/containers/service/TokenServiceFeaturesContainer'
import TokenServiceCopyTradingContainer from '@feature/landing/pages/token/containers/service/TokenServiceCopyTradingContainer'
import TokenServiceLOTTokenContainer from '@feature/landing/pages/token/containers/service/TokenServiceLOTTokenContainer'
import TokenServiceSupportExchangeContainer from '@feature/landing/pages/token/containers/service/TokenServiceSupportExchangeContainer'

interface IProps {
    className?: string
}

const TokenServiceContainer: React.FC<IProps> = ({className}) => {
    return (
        <div
            data-cy={'tokenLanding_screen_about'}
            className={classNames('transition-opacity duration-500 ease-in-out', className)}>
            <TokenServiceFeaturesContainer />
            <TokenServiceCopyTradingContainer />
            <TokenServiceLOTTokenContainer />
            <TokenServiceSupportExchangeContainer />
        </div>
    )
}
export default TokenServiceContainer
