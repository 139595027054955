import {create} from 'zustand'

export const TokenLandingTypes = {
    SERVICE: 'service',
    TOKEN: 'token',
} as const

export type TokenLandingType = (typeof TokenLandingTypes)[keyof typeof TokenLandingTypes]

type TokenLandingStoreState = {
    pageType?: TokenLandingType
}

type TokenLandingStoreAction = {
    setPageType?: (pageType: TokenLandingType) => void
}

const useTokenLandingStore = create<TokenLandingStoreState & TokenLandingStoreAction>()((set, get) => {
    return {
        pageType: TokenLandingTypes.SERVICE,
        setPageType: (pageType: TokenLandingType) => set({pageType}),
    }
})

export default useTokenLandingStore
