import React, {useRef} from 'react'
import FadeInView from '@component/animation/fadeIn/FadeInView'
import {isVisible} from '@testing-library/user-event/utils/misc/isVisible'
import Logo from '@svg/common/ic_logo_white.svg'
import {useIsVisible} from '@hook/useIsVisible'
import {useTranslation} from 'next-i18next'
import useWindowSize from '@hook/useWindowSize'
import Text from '@component/Text/Text'
import TagText from '@component/Text/TagText'

import IconCountries from '@svg/token/icon_lot_service_info_countries.svg'
import IconHistory from '@svg/token/icon_lot_service_info_history.svg'
import IconTrading from '@svg/token/icon_lot_service_info_trading.svg'
import useTokenLandingStore, {TokenLandingTypes} from '@feature/landing/pages/token/hooks/useTokenLandingStore'

interface IProps {
    className?: string
}

const TokenServiceInfoContainer: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const {isMd} = useWindowSize()

    const {pageType} = useTokenLandingStore()
    const fadeAnimationRef = useRef(null)
    const isVisible = useIsVisible(fadeAnimationRef)

    const ICON_SIZE = isMd ? 50 : 80
    return (
        <div
            className={`bg-gradient-to-b from-transparent to-[#1F1F26] py-[200px] lg2:py-[150px] md:py-[100px] sm:py-[70px] ${className}`}>
            <div
                className={
                    'layout-full_desktop max-w-[1280px] px-[20px] flex items-center gap-x-[100px] lg2:gap-x-[50px] lg2:px-[40px] md:px-[20px] sm:flex-col sm:gap-y-[50px]'
                }>
                <div className={'flex-1 flex flex-col items-start justify-center gap-y-[90px] md:gap-y-[20px]'}>
                    <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.SERVICE}>
                        <div className={'flex items-center gap-x-[10px]'}>
                            <Logo width={isMd ? 16 : 40} height={isMd ? 20 : 50} />
                            <Text
                                className={
                                    'text-white text-landing_heading2 md:text-landing_body_large md:font-semibold !font-landing'
                                }>
                                {t('landingTokenSale.TokenService.features.subtitle1')}
                            </Text>
                        </div>
                        <Text
                            className={
                                'text-white text-landing_heading2 md:text-landing_body_large md:font-semibold !font-landing'
                            }>
                            {t('landingTokenSale.TokenService.features.subtitle2')}
                        </Text>
                    </FadeInView>
                    <FadeInView isVisible={isVisible && pageType === TokenLandingTypes.SERVICE}>
                        <Text className={'text-[#C9CAC3] typo-landing_body_medium md:typo-landing_body_caption'}>
                            {t('landingTokenSale.TokenService.features.desc2')}
                        </Text>
                    </FadeInView>
                </div>
                <div className={'flex-1 flex flex-col gap-y-[90px] md:gap-y-[30px]'}>
                    <div className={'flex items-center gap-x-[20px]'}>
                        <IconCountries width={ICON_SIZE} height={ICON_SIZE} />
                        <TagText
                            className={
                                'text-white text-landing_display2 md:text-landing_body_small md:font-semibold !font-landing'
                            }>
                            {t('landingTokenSale.TokenService.feature1')}
                        </TagText>
                    </div>

                    <div className={'flex items-center gap-x-[20px]'}>
                        <IconHistory width={ICON_SIZE} height={ICON_SIZE} />
                        <TagText
                            className={
                                'text-white text-landing_display2 md:text-landing_body_small md:font-semibold !font-landing'
                            }>
                            {t('landingTokenSale.TokenService.feature2')}
                        </TagText>
                    </div>

                    <div ref={fadeAnimationRef} className={'flex items-center gap-x-[20px]'}>
                        <IconTrading width={ICON_SIZE} height={ICON_SIZE} />
                        <TagText
                            className={
                                'text-white text-landing_display2 md:text-landing_body_small md:font-semibold !font-landing'
                            }>
                            {t('landingTokenSale.TokenService.feature3')}
                        </TagText>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TokenServiceInfoContainer
